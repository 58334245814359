const modelStyle = {
  model: {
    position: "relative",
    paddingBottom: "56.25%",
    //paddingTop: "25px",
    width: "75%",
    height: "0",
  },
  // See _plugin_mdoel-viewer.scss!
};

export default modelStyle;
