import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ModelViewer from "components/ModelViewer/ModelViewer.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/products/hoivakotilive/hoivakotilive_slide_1.jpg";
import image2 from "assets/img/products/hoivakotilive/hoivakotilive_slide_2.jpg";
import image3 from "assets/img/products/hoivakotilive/hoivakotilive_slide_3.jpg";
import image4 from "assets/img/products/hoivakotilive/hoivakotilive_slide_4.jpg";
import image5 from "assets/img/products/hoivakotilive/hoivakotilive_slide_5.jpg";
import image6 from "assets/img/products/hoivakotilive/hoivakotilive_slide_6.jpg";
import image7 from "assets/img/products/hoivakotilive/hoivakotilive_slide_7.jpg";
import image8 from "assets/img/products/hoivakotilive/hoivakotilive_slide_8.jpg";
import image9 from "assets/img/products/hoivakotilive/hoivakotilive_slide_9.jpg";
import image10 from "assets/img/products/hoivakotilive/hoivakotilive_slide_10.jpg";
import image11 from "assets/img/products/hoivakotilive/hoivakotilive_slide_13.jpg";
import image12 from "assets/img/products/hoivakotilive/hoivakotilive_slide_14.jpg";

import Vantaa from "assets/img/logos/vantaa-480x480.png";
import Oulu from "assets/img/logos/oulu-480x480.png";
import Telia from "assets/img/logos/telia-480x480.png";
import FinCloud from "assets/img/logos/fincloud-480x480.png";
import UnivOulu from "assets/img/logos/univoulu-480x480.png";

class LiveSYNCPresentSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Ruskotunturi AR Experience</h2>
              <h4>We create 3D models and interactive AR experiences from drone or 360° videos</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Demo",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <ModelViewer
                        modelSrcURL="assets/ruskotunturi.glb"
                        modelTitle="Ruskotunturi"
                        aspectRatio="0%"
                      />
                    </div>)
                },
                {/* 
                {
                  tabName: "Photos",
                  tabIcon: "",
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image7} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image8} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image9} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image10} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image11} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image12} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Just like everyone else, elderly people living in nursing homes get pleasure
                          and joy from contacts with family members and friends. Due to long distances or
                          temporary visiting restrictions - especially during the COVID-19 pandemic -
                          such contacts have been all too few. While teleconferencing solutions cannot replace
                          real physical contact, they are the next best thing and generally safe to use.
                        </p>
                        <p>
                          Video calls via commonly available apps that are designed for the young, techonology
                          oriented people tend to have too much features and complexity for the elderly.
                          In addition, elderly persons often have some limitations in their abilities to
                          use technology, for example reduced hearing ability. Ease of use, large screen,
                          loud enough sound, large buttons, etc. are mandatory features for the elderly.
                        </p>
                        <p>
                          Finwe developed a new kind of teleconferencing solution for nursing homes as
                          part of the EU funded CoHeWe project (Co-created Health and Wellbeing). The
                          solution was designed and field-tested in two nursing homes, run by the city
                          of Vantaa and the city of Oulu. University of Oulu participated by organizing
                          feedback collection and analysis. Some of the components and network connections
                          were kindly provided for the field-tests by Telia.
                        </p>
                        <p>
                          The solution consists of two units that are paired together: one for the nursing
                          home and another one to be lended to family members or friends. Calling was
                          made possible only between the paired devices. Both units contain a large 5G tablet
                          computer and special kind of Telia mobile broadband subscription for enhanced
                          security and performance. The nursing home unit is built as a ruggedized movable
                          unit with wheels and an additional large, curvy display unit and sound unit.
                          The lendable unit is basiclaly the opposite: a small set of devices that come in
                          a hard case and contain only battery-powered devices - tablet, 360° camera, and
                          a telescopic stand for the 360° camera.
                        </p>
                        <p>
                          The solution has been field-tested for several months and used both for video
                          calls between nursing home residents and their family members, as well as for
                          producing live broadcasts to a group of residents for example from local school
                          or an outdoor event with animals. In addition, the solution turned invaluable
                          when COVID-19 stopped all visits. The feedback has been positive, although 5G
                          networks are still being built and the network coverage wasn't very good at the
                          time of the field test.
                        </p>
                        <p>
                          <b>
                            To learn more of Hoivakoti Live or other customized teleconferencing solutions,
                            contact us.
                          </b>
                        </p>
                        <Button
                          round
                          color="success"
                          size="md"
                          href="contact-page/"
                          target="_self"
                          rel="noopener noreferrer"
                        >
                          Contact us
                        </Button>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Customers:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Vantaa}
                              alt="City of Vantaa"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Oulu}
                              alt="City of Oulu"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Partners:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Telia}
                              alt="Telia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={UnivOulu}
                              alt="University of Oulu"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={FinCloud}
                              alt="FinCloud"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                }
                */}
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(LiveSYNCPresentSection);
