import React from "react"
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import modelStyle from "assets/jss/finwe/modelStyle.jsx";

function ModelViewer({ ...props }) {
  const { classes, className, modelSrcURL, modelTitle, aspectRatio, ...rest } = props;
  const modelClasses = classNames({
    [classes.model]: true,
    [className]: className !== undefined
  });
  if (typeof window === 'undefined') return <span>Loading...</span>
  const Component = React.lazy(async () => {
    // load this module at runtime to workaround gatsby aggressively
    // trying to pre-render it
    await import("@google/model-viewer");
    return { default: Viewer };
  })

  function onShowHotspots() {
    //console.log("onShowHotspots");

    const modelViewer = document.querySelector('#model-viewer-1');
    if (!modelViewer) {
      console.log("model-viewer not yet loaded!");
      return;
    }

    /*
    modelViewer.querySelector('#src').addEventListener('input', (event) => {
      modelViewer.src = event.target.value;
    });
    */

    const checkbox = modelViewer.querySelector('#show-hotspots');
    //console.log(checkbox.checked);
    modelViewer.querySelectorAll('button.Hotspot').forEach((hotspot) => {
      //console.log(hotspot);
      if (checkbox.checked) {
        hotspot.classList.remove('hide');
      } else {
        hotspot.classList.add('hide');
      }    
    });
  }

  var onHotspotClicked = function(hotspotId, cameraOrbit) {
    console.log("Hotspot " + hotspotId + " clicked!");

    const modelViewer = document.querySelector('#model-viewer-1');
    if (modelViewer) {
      modelViewer.cameraOrbit=cameraOrbit; 
    }
  }

  function Viewer() {
    return (
      <div>
        <model-viewer className={classes.modelViewer} id="model-viewer-1"
          src="model/Ruskotunturi_v8.glb"
          alt="3D model"
          style={{ width: "100%", height: "60vh" }}
          camera-controls
          //auto-rotate
          ar
          ar-modes="webxr scene-viewer quick-look"
          exposure="1.0"
          shadow-intensity="5.1"
          shadow-softness="1"
          skybox-image="model/spruit_sunrise_1k_HDR.hdr" // 360 backdrop image
          environment-image="model/spruit_sunrise_1k_HDR.hdr" // Lighting image
          poster="/model/Ruskotunturi.png" // Image while loading the model
          camera-target="auto -0.65m auto" // Camera rotation center point (x,y,z)
          camera-orbit="260.0deg 75.0deg 1.50m" // Initial camera position/look-at
          min-camera-orbit="auto 0deg auto" // yaw: 360, pitch: 0-90
          max-camera-orbit="auto 85deg auto"
          min-field-of-view="20deg" // max zoom in
          max-field-of-view="45deg" // max zoom out
        >
          <button className="Hotspot"
            slot="hotspot-1"
            data-position="0.46180643081084033m -0.2615278183656834m 0.20441789111529357m"
            data-normal="0.0m 1.0m 0.0"
            data-visibility-attribute="visible"
            onClick={onHotspotClicked.bind(this, 1, '45deg 50deg 0.8m')}>
            <div className="HotspotAnnotation">Peak 50m</div>
          </button>

          <button className="Hotspot"
            slot="hotspot-2"
            data-position="-0.1145876813837568m -0.4192332123227038m -0.36266298413966924m"
            data-normal="0.0m 1.0m 0.0"
            data-visibility-attribute="visible"
            onClick={onHotspotClicked.bind(this, 2, '-145deg 70deg 0.9m')}>
            <div className="HotspotAnnotation">Lift 1</div>
          </button>

          <button className="Hotspot"
            slot="hotspot-3"
            data-position="-0.049787806481420754m -0.40238197832846984m -0.15941562243100718m"
            data-normal="0.0m 1.0m 0.0"
            data-visibility-attribute="visible"
            onClick={onHotspotClicked.bind(this, 3, '-120deg 68deg 1.0m')}>
            <div className="HotspotAnnotation">Lift 2</div>
          </button>

          <button className="Hotspot"
            slot="hotspot-4"
            data-position="0.2486232994470492m -0.30548477086455006m 0.15236796514695738m"
            data-normal="0.0m 1.0m 0.0"
            data-visibility-attribute="visible"
            onClick={onHotspotClicked.bind(this, 4, '-100deg 65deg 1.1m')}>
            <div className="HotspotAnnotation">Lift 3</div>
          </button>

          <button className="Hotspot"
            slot="hotspot-5"
            data-position="-0.5045030094243591m -0.45709103772384285m -0.06847985641799514m"
            data-normal="0.0m 1.0m 0.0"
            data-visibility-attribute="visible"
            onClick={onHotspotClicked.bind(this, 5, '-100deg 70deg 0.7m')}>
            <div className="HotspotAnnotation">Cafeteria &amp; tickets</div>
          </button>

          <div className="progress-bar hide" slot="progress-bar">
            <div className="update-bar"></div>
          </div>

          <button slot="ar-button" id="ar-button">
            View in AR
          </button>

          <div id="controls" className="controlItem">
            <input id="show-hotspots" type="checkbox" defaultChecked="{true}" onChange={onShowHotspots} />
            <label htmlFor="show-hotspots">SHOW HOTSPOTS</label>
          </div>

          <div id="ar-prompt">
            <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
          </div>

        </model-viewer>
      </div>
    )
  }

  return (
    <React.Suspense fallback={<span>Loading model...</span>}>
      <Component />
    </React.Suspense>
  )
}

ModelViewer.defaultProps = {
  modelTitle: "",
  aspectRatio: "56.25%"
};

ModelViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  modelSrcURL: PropTypes.string.isRequired,
  modelTitle: PropTypes.string.isRequired,
  aspectRatio: PropTypes.string,
};

export default withStyles(modelStyle)(ModelViewer);
